import React, { useEffect, useState, createElement, useRef } from "react";

const useScriptCreation = (url) => {
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [scriptError, setScriptError] = useState(null);

  useEffect(() => {
    if (!url) return;

    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    script.onload = () => {
      setScriptLoaded(true);
      setScriptError(null);
    };

    script.onerror = () => {
      setScriptError(new Error(`Failed to load script: ${url}`));
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return { isScriptLoaded, scriptError };
};

function HOComponent({ name, reference, id }) {
  const element = createElement(name, {
    "wp-app-parameter-id": id,
    ref: reference,
  });
  return element;
}

export default function ComponentApp() {
  const [webComponent, setWebComponent] = useState(null);
  const [parameterId, setParameterId] = useState(null);
  const [error, setError] = useState(null); // Error state
  const reference = useRef();

  useEffect(() => {
    const path = window.location.pathname;
    const webComponentParameterId = path.split("/").pop();

    const apiUrl = `https://apps.wpintegrate.com/web/web-component/parameter/${webComponentParameterId}`;
    const fetchWebComponent = async () => {
      try {
        setParameterId(webComponentParameterId);
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setWebComponent(data);
      } catch (error) {
        console.error(error.message);
        setError(error.message); // Set the error message
      }
    };
    if (webComponentParameterId) {
      fetchWebComponent();
    }
  }, []);

  const scriptURL = webComponent
    ? `https://apps.wpintegrate.com/api/public/webComponents/${webComponent._id}/component`
    : "";

  useScriptCreation(scriptURL);

  // Render loading and error states

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (webComponent) {
    return (
      <HOComponent
        name={webComponent.element}
        reference={reference}
        id={parameterId}
      />
    );
  }

  return <></>;
}
